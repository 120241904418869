var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-breadcrumb',{staticClass:"breadcrumb-slash mb-1"},[_c('b-breadcrumb-item',{attrs:{"to":{ name: 'admin-products-list' }}},[_vm._v(" รายการสินค้า ")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v(" "+_vm._s(_vm.isEdit ? "รายละเอียดสินค้า" : "เพิ่มสินค้า")+" ")])],1),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('b-card',{attrs:{"title":" รายละเอียดสินค้า"}},[_c('hr'),_c('h5',{staticClass:"my-2"},[_vm._v("ภาพสินค้า")]),_c('div',[_c('el-upload',{ref:"upload",class:_vm.formDataImg ? 'disabless' : '',attrs:{"action":"#","list-type":"picture-card","auto-upload":false,"on-preview":_vm.handlePictureCardPreview,"on-remove":_vm.handleRemove,"on-change":_vm.handleChangeImg,"file-list":_vm.fileList,"accept":"image/*"}},[_c('i',{staticClass:"el-icon-plus"})]),_c('el-dialog',{attrs:{"visible":_vm.dialogVisible},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('img',{attrs:{"width":"100%","src":_vm.dialogImageUrl,"alt":""}})])],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"รหัสสินค้า :","label-cols":"4","label-for":"productCode"}},[_c('validation-provider',{attrs:{"name":"ProductCode","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"productCode"},model:{value:(_vm.productModel.productCode),callback:function ($$v) {_vm.$set(_vm.productModel, "productCode", $$v)},expression:"productModel.productCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"ชื่อสินค้า :","label-cols":"4","label-for":"productName"}},[_c('validation-provider',{attrs:{"name":"ProductName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"productName"},model:{value:(_vm.productModel.productName),callback:function ($$v) {_vm.$set(_vm.productModel, "productName", $$v)},expression:"productModel.productName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"หมวดหมู่สินค้า :","label-cols":"4","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"label","options":_vm.optionsProductCategory,"push-tags":"","taggable":""},model:{value:(_vm.selectedProductCategory),callback:function ($$v) {_vm.selectedProductCategory=$$v},expression:"selectedProductCategory"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"จำนวนวันแจ้งเตือน :","label-cols":"4","label-for":"alert_day"}},[_c('validation-provider',{attrs:{"name":"alert_day","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"alert_day"},model:{value:(_vm.productModel.alert_day),callback:function ($$v) {_vm.$set(_vm.productModel, "alert_day", $$v)},expression:"productModel.alert_day"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticStyle:{"display":"none"},attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"กลุ่มสินค้า :","label-cols":"4","label-for":"productExchange"}},[_c('validation-provider',{attrs:{"name":"productExchange"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"productExchange","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"label","options":_vm.optionsProductExchange,"push-tags":"","taggable":""},model:{value:(_vm.selectedProductExchange),callback:function ($$v) {_vm.selectedProductExchange=$$v},expression:"selectedProductExchange"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"โค้ดสีสินค้า :","label-cols":"4","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.productModel.productColor),expression:"productModel.productColor"}],staticStyle:{"height":"3rem","min-width":"50%","border":"none","background":"transparent"},attrs:{"type":"color","value":"#ff0000"},domProps:{"value":(_vm.productModel.productColor)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.productModel, "productColor", $event.target.value)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"ราคาสินค้า :","label-cols":"4","label-for":"price"}},[_c('validation-provider',{attrs:{"name":"price","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"price"},model:{value:(_vm.productModel.price),callback:function ($$v) {_vm.$set(_vm.productModel, "price", $$v)},expression:"productModel.price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"ราคาส่ง :","label-cols":"4","label-for":"price"}},[_c('validation-provider',{attrs:{"name":"minPrice","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"minPrice"},model:{value:(_vm.productModel.minPrice),callback:function ($$v) {_vm.$set(_vm.productModel, "minPrice", $$v)},expression:"productModel.minPrice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"สถานะการใช้งาน :","label-cols":"4","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"checked":"true","name":"check-button","switch":"","inline":""},model:{value:(_vm.productModel.active),callback:function ($$v) {_vm.$set(_vm.productModel, "active", $$v)},expression:"productModel.active"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":"แม่ทีม :","label-cols":"6","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"mt-50",attrs:{"checked":false,"value":_vm.leadRoleId},model:{value:(_vm.leadteamcheck),callback:function ($$v) {_vm.leadteamcheck=$$v},expression:"leadteamcheck"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":"พนักงานขาย :","label-cols":"6","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"mt-50",attrs:{"checked":false,"value":_vm.sellRoleId},model:{value:(_vm.sellercheck),callback:function ($$v) {_vm.sellercheck=$$v},expression:"sellercheck"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-card-footer',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-button',{staticClass:"mr-1",staticStyle:{"width":"10rem"},attrs:{"to":{ name: 'admin-products-list' },"variant":"danger"}},[_vm._v(" กลับ ")]),_c('b-button',{staticStyle:{"width":"15rem"},attrs:{"variant":"primary","disabled":_vm.isWork},on:{"click":_vm.validationForm}},[_vm._v(" บันทึกข้อมูล ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }