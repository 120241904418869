<template>
  <div>
    <b-breadcrumb class="breadcrumb-slash mb-1">
      <b-breadcrumb-item :to="{ name: 'admin-products-list' }">
        รายการสินค้า
      </b-breadcrumb-item>
      <b-breadcrumb-item active>
        {{ isEdit ? "รายละเอียดสินค้า" : "เพิ่มสินค้า" }}
      </b-breadcrumb-item>
    </b-breadcrumb>

    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="validationForm">
        <b-card title=" รายละเอียดสินค้า">
          <hr />
          <h5 class="my-2">ภาพสินค้า</h5>

          <div>
            <el-upload
              ref="upload"
              action="#"
              list-type="picture-card"
              :auto-upload="false"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :on-change="handleChangeImg"
              :file-list="fileList"
              accept="image/*"
              :class="formDataImg ? 'disabless' : ''"
            >
              <i class="el-icon-plus" />
            </el-upload>

            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
            <!-- 
            <p
              class="mt-1 mb-2"
              style="font-size:12px;"
            >
              1. ขนาด : ไม่เกิน 30 Mb, 1280x1280px <br>
              2. ระยะเวลา : 10-60 วินาที <br>
              3. นามสกุลไฟล์ : MP4 <br>
              4. หมายเหตุ : คุณสามารถเผยแพร่สินคำชิ้นนี้ได้ตั้งแต่ระหว่างที่วิดีโอกำลังอัปโหลด โดยวิดีโอจะแสดงให้เห็นหลังการอัปโหลดเสร็จสิ้น
            </p> -->
          </div>
          <!-- <b-row>
            <b-col md="6">
              <b-form-group
                label="หมายเลขสินค้า(SKU) :"
                label-cols="4"
                label-for="name"
              >
                <validation-provider #default="{ errors }" name="productId">
                  <b-form-input
                    id="productId"
                    v-model="productModel.productId"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row> -->
          <b-row>
            <b-col md="6">
              <b-form-group
                label="รหัสสินค้า :"
                label-cols="4"
                label-for="productCode"
              >
                <validation-provider
                  #default="{ errors }"
                  name="ProductCode"
                  rules="required"
                >
                  <b-form-input
                    id="productCode"
                    v-model="productModel.productCode"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="ชื่อสินค้า :"
                label-cols="4"
                label-for="productName"
              >
                <validation-provider
                  #default="{ errors }"
                  name="ProductName"
                  rules="required"
                >
                  <b-form-input
                    id="productName"
                    v-model="productModel.productName"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">



              <b-form-group
                label="หมวดหมู่สินค้า :"
                label-cols="4"
                label-for="description"
              >
                <validation-provider #default="{ errors }" name="Description">
                  <v-select
                    v-model="selectedProductCategory"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="label"
                    :options="optionsProductCategory"
                    push-tags
                    taggable
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>



            </b-col>


            <b-col md="6">
              <b-form-group
                label="จำนวนวันแจ้งเตือน :"
                label-cols="4"
                label-for="alert_day"
              >
                <validation-provider
                  #default="{ errors }"
                  name="alert_day"
                  rules="required"
                >
                  <b-form-input id="alert_day" v-model="productModel.alert_day" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>



            <b-col md="6" style="display: none;">
              <b-form-group
                label="กลุ่มสินค้า :"
                label-cols="4"
                label-for="productExchange"
              >
                <validation-provider
                  #default="{ errors }"
                  name="productExchange"
                >
                  <v-select
                    id="productExchange"
                    v-model="selectedProductExchange"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="label"
                    :options="optionsProductExchange"
                    push-tags
                    taggable
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- <b-row>
            <b-col md="6">
              <b-form-group
                label="Product Brand :"
                label-cols="4"
                label-for="description"
              >
                <validation-provider #default="{ errors }" name="Description">
                  <v-select
                    v-model="selectedBrand"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="label"
                    :options="optionsBrand"
                    push-tags
                    taggable
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Product Model :"
                label-cols="4"
                label-for="description"
              >
                <validation-provider #default="{ errors }" name="Description">
                  <v-select
                    v-model="selectedProductModel"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="label"
                    :options="optionsProductModel"
                    push-tags
                    taggable
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row> -->

          <!-- <b-row>
            <b-col md="6">
              <b-form-group
                label="Product Year :"
                label-cols="4"
                label-for="description"
              >
                <validation-provider #default="{ errors }" name="Description">
                  <v-select
                    v-model="selectedProductYear"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="label"
                    :options="optionsProductYear"
                    push-tags
                    taggable
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Product SubModel :"
                label-cols="4"
                label-for="description"
              >
                <validation-provider #default="{ errors }" name="Description">
                  <v-select
                    v-model="selectedProductSubModel"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="label"
                    :options="optionsProductSubModel"
                    push-tags
                    taggable
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row> -->

          <b-row>
            <b-col md="6">
              <b-form-group
                label="โค้ดสีสินค้า :"
                label-cols="4"
                label-for="description"
              >
                <validation-provider #default="{ errors }" name="Description">
                  <input
                    type="color"
                    value="#ff0000"
                    style="
                      height: 3rem;
                      min-width: 50%;
                      border: none;
                      background: transparent;
                    "
                    v-model="productModel.productColor"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group
                label="ราคาสินค้า :"
                label-cols="4"
                label-for="price"
              >
                <validation-provider
                  #default="{ errors }"
                  name="price"
                  rules="required"
                >
                  <b-form-input id="price" v-model="productModel.price" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="ราคาส่ง :" label-cols="4" label-for="price">
                <validation-provider
                  #default="{ errors }"
                  name="minPrice"
                  rules="required"
                >
                  <b-form-input id="minPrice" v-model="productModel.minPrice" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="สถานะการใช้งาน :"
                label-cols="4"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <b-form-checkbox
                    v-model="productModel.active"
                    checked="true"
                    name="check-button"
                    switch
                    inline
                  >
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="2">
              <b-form-group label="แม่ทีม :" label-cols="6" label-for="name">
                <validation-provider #default="{ errors }" name="Name">
                  <b-form-checkbox
                    :checked="false"
                    class="mt-50"
                    :value="leadRoleId"
                    v-model="leadteamcheck"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="2">
              <b-form-group
                label="พนักงานขาย :"
                label-cols="6"
                label-for="name"
              >
                <validation-provider #default="{ errors }" name="Name">
                  <b-form-checkbox
                    :checked="false"
                    class="mt-50"
                    :value="sellRoleId"
                    v-model="sellercheck"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- <hr /> -->
          <!-- <b-card-title>เกี่ยวกับสินค้า</b-card-title> -->

          <!-- <h5 class="my-2">ภาพ</h5> -->

          <!-- <div>
            <el-upload
              ref="upload"
              action="#"
              list-type="picture-card"
              :auto-upload="false"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemoveSub"
              :on-change="handleChangeImgSub"
              :file-list="fileListSub"
              accept="image/*"
              :class="formDataImgSub ? 'disabless' : ''"
            >
              <i class="el-icon-plus" />
            </el-upload>

            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
            
            <p
              class="mt-1 mb-2"
              style="font-size:12px;"
            >
              1. ขนาด : ไม่เกิน 30 Mb, 1280x1280px <br>
              2. ระยะเวลา : 10-60 วินาที <br>
              3. นามสกุลไฟล์ : MP4 <br>
              4. หมายเหตุ : คุณสามารถเผยแพร่สินคำชิ้นนี้ได้ตั้งแต่ระหว่างที่วิดีโอกำลังอัปโหลด โดยวิดีโอจะแสดงให้เห็นหลังการอัปโหลดเสร็จสิ้น
            </p>
          </div> -->
          <!-- <b-row>
            <b-col md="6">
              <b-form-group label="brand :" label-cols="4" label-for="name">
                <validation-provider #default="{ errors }" name="productId">
                  <b-form-input id="productId" v-model="productModel.brand" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="model :" label-cols="4" label-for="name">
                <validation-provider #default="{ errors }" name="productId">
                  <b-form-input id="productId" v-model="productModel.model" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row> -->

          <!-- <b-row>
            <b-col md="6">
              <b-form-group label="year :" label-cols="4" label-for="name">
                <validation-provider #default="{ errors }" name="productId">
                  <b-form-input id="productId" v-model="productModel.year" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="subModel :" label-cols="4" label-for="name">
                <validation-provider #default="{ errors }" name="productId">
                  <b-form-input
                    id="productId"
                    v-model="productModel.subModel"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row> -->

          <b-card-footer class="d-flex align-items-center justify-content-end">
            <b-button
              :to="{ name: 'admin-products-list' }"
              variant="danger"
              style="width: 10rem;"
              class="mr-1"
            >
              <!-- <b-spinner
                v-if="isShowLoading"
                small
                class="mr-1"
              /> -->
              กลับ
            </b-button>
            <b-button
              variant="primary"
              style="width: 15rem;"
              :disabled="isWork"
              @click="validationForm"
            >
              <!-- <b-spinner
                v-if="isShowLoading"
                small
                class="mr-1"
              /> -->
              บันทึกข้อมูล
            </b-button>
          </b-card-footer>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BLink,
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BForm,
  BTable,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardFooter,
  BFormCheckbox,
  BFormDatepicker,
  BFormRadioGroup,
  BFormTextarea,
  BBreadcrumb,
  BBreadcrumbItem,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import Cleave from "vue-cleave-component";
import { ref, onUnmounted } from "@vue/composition-api";
import { stringToNumber } from "@/common";

import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// eslint-disable-next-line import/no-cycle
import router from "@/router";
import store from "@/store";
import productStoreModule from "./productStoreModule";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BForm,
    vSelect,
    Cleave,
    BFormTextarea,
    BBreadcrumb,
    BBreadcrumbItem,
    // BTable,
    // BCardHeader,
    BCardTitle,
    BCardText,
    BCardFooter,
    BFormCheckbox,
    BFormDatepicker,
    BFormRadioGroup,
    // BLink,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      // validation rules
      required,
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
        },
      },
      dialogImageUrl: "",
      dialogVisible: false,
      optionsTest: [{ value: 1, text: "test" }],
      minDate: new Date(),
      subImgId: null,
      isWork: false,
    };
  },
  directives: {
    Ripple,
  },
  setup() {
    if (!store.hasModule("product-list"))
      store.registerModule("product-list", productStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule("product-list"))
        store.unregisterModule("product-list");
    });

    const sellercheck = ref(null);
    const leadteamcheck = ref(null);
    const categoryOptions = [{ label: "", value: "" }];

    const sizeOptions = [{ label: "", value: "" }];

    const colorOptions = [{ label: "", value: "" }];

    const shippings = ref([]);
    const selectedCategory = ref({});
    const selectedBox = ref({});
    const optionsCategory = ref([]);

    const fileList = ref([]);
    const fileListSub = ref([]);
    // const productModel = ref(null)
    const formDataImg = ref(null);
    const formDataImgSub = ref(null);

    const sellRoleId = ref("");
    const leadRoleId = ref("");

    store.dispatch("product-list/getRoleAll").then((response) => {
      if (response !== null) {
        response.data.data.forEach((data) => {
          if (data.roleName == "แม่ทีม") {
            leadRoleId.value = data.roleId;
          }
          if (data.roleName == "พนักงานขาย") {
            sellRoleId.value = data.roleId;
          }
        });
      }
    });

    const productModel = ref({
      productId: "",
      productCode: "",
      productName: "",
      price: 0,
      alert_day: 0,
      minPrice: 0,
      maxPrice: 0,
      description: "",
      product_Image_Id: "",
      productBrand: "",
      productModels: "",
      productSubModels: "",
      productYear: "",
      brand: "",
      model: "",
      year: "",
      subModel: "",
      subModelImageId: "00000000-0000-0000-0000-000000000000",
      productColor: "",
      active: true,
      exchangeProductGroup: "",
    });

    const optionsBrand = ref([]);
    const fetchBrands = () => {
      store.dispatch("product-list/getProductBrand").then((response) => {
        if (response !== null) {
          response.data.data.forEach((data) => {
            optionsBrand.value.push({
              label: data.brand,
            });
          });
        }
      });
    };
    const selectedBrand = ref(null);
    fetchBrands();

    const optionsProductModel = ref([]);
    const fetchProductModels = (brand) => {
      store
        .dispatch("product-list/getProductModel", { brand: brand })
        .then((response) => {
          if (response !== null) {
            optionsProductModel.value = [];
            response.data.data.forEach((data) => {
              optionsProductModel.value.push({
                label: data.model,
              });
            });
          }
        });
    };
    const selectedProductModel = ref(null);

    const optionsProductYear = ref([]);
    const fetchProductYears = (brand, model) => {
      store
        .dispatch("product-list/getProductYear", { brand: brand, model: model })
        .then((response) => {
          if (response !== null) {
            optionsProductYear.value = [];
            response.data.data.forEach((data) => {
              optionsProductYear.value.push({
                label: data.year,
              });
            });
          }
        });
    };
    const selectedProductYear = ref(null);

    const optionsProductSubModel = ref([]);
    const fetchProductSubModel = (brand, model, year) => {
      store
        .dispatch("product-list/getProductSubModel", {
          brand: brand,
          model: model,
          year: year,
        })
        .then((response) => {
          if (response !== null) {
            optionsProductSubModel.value = [];
            response.data.data.forEach((data) => {
              optionsProductSubModel.value.push({
                label: data.subModel,
              });
            });
          }
        });
    };
    const optionsProductCategory = ref([]);
    const fetchProductCategorys = () => {
      store.dispatch("product-list/getProductCategory").then((response) => {
        if (response !== null) {
          response.data.data.forEach((data) => {
            optionsProductCategory.value.push({
              label: data.categoryName,
              value: data.categoryId,
            });
          });
        }
      });
    };
    const selectedProductCategory = ref(null);
    fetchProductCategorys();

    const optionsProductExchange = ref([]);
    const fetchProductExchange = () => {
      store.dispatch("product-list/getProductExchange").then((response) => {
        if (response !== null) {
          response.data.data.forEach((data) => {
            optionsProductExchange.value.push(data.exchange);
          });
        }
      });
    };
    const selectedProductExchange = ref(null);
    fetchProductExchange();

    const selectedProductSubModel = ref(null);
    const isEdit = ref(false);
    if (router.currentRoute.name === "admin-products-edit") {
      isEdit.value = true;
      store
        .dispatch("product-list/fetchProduct", {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          productModel.value = {
            productId: response.data.productId,
            productCode: response.data.productCode,
            productName: response.data.productName,
            price: response.data.price,
            alert_day: response.data.alert_day,
            minPrice: response.data.minPrice,
            maxPrice: 0,
            description: "",
            product_Image_Id: response.data.product_Image_Id.media_ID,
            productBrand: response.data.productBrand,
            productModels: response.data.productModels,
            productSubModels: response.data.productSubModels,
            productYear: response.data.productYear,
            brand: response.data.brand,
            model: response.data.model,
            year: response.data.year,
            subModel: response.data.subModel,
            subModelImageId: response.data.subModelImageId.media_ID,
            productColor: response.data.productColor,
            active: response.data.active,
            exchangeProductGroup: response.data.exchangeProductGroup,
          };
          selectedProductCategory.value = {
            value: response.data.productCategory.categoryId,
            label: response.data.productCategory.categoryName,
          };
          selectedProductExchange.value = response.data.exchangeProductGroup;
          leadteamcheck.value =
            response.data.teamRoleId && response.data.teamRoleId.roleId;
          sellercheck.value =
            response.data.salesRoleId && response.data.salesRoleId.roleId;
          fileList.value.push({
            name: response.data.product_Image_Id.media_ID,
            url: response.data.product_Image_Id.url,
            raw: null,
            status: "success",
            // percentage: 0,
            // size: 0,
            // uid: file.id,
            // url: `data:${file.contentType};base64,${file.fileData}`,
          });

          if (response.data.subModelImageId.url) {
            fileListSub.value.push({
              name: response.data.subModelImageId.media_ID,
              url: response.data.subModelImageId.url,
              raw: null,
              status: "success",
              // percentage: 0,
              // size: 0,
              // uid: file.id,
              // url: `data:${file.contentType};base64,${file.fileData}`,
            });
          }

          optionsProductSubModel.value.push({
            label: response.data.productSubModels,
          });
          optionsProductYear.value.push({
            label: response.data.productYear,
          });
          optionsProductModel.value.push({
            label: response.data.productModels,
          });

          selectedBrand.value = {
            label: response.data.productBrand,
          };
          selectedProductModel.value = {
            label: response.data.productModels,
          };
          selectedProductSubModel.value = {
            label: response.data.productSubModels,
          };
          selectedProductYear.value = {
            label: response.data.productYear,
          };
        })
        .catch((error) => {
          if (error.response.status === 404) {
          }
        });
    }

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        // eslint-disable-next-line no-param-reassign
        productModel.value.avatar = base64;
      }
    );

    return {
      shippings,

      categoryOptions,
      sizeOptions,
      colorOptions,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
      fileList,
      optionsCategory,
      selectedCategory,
      selectedBox,
      productModel,
      formDataImg,
      optionsBrand,
      selectedBrand,
      optionsProductModel,
      fetchProductModels,
      selectedProductModel,
      optionsProductYear,
      fetchProductYears,
      selectedProductYear,
      fetchProductSubModel,
      optionsProductSubModel,
      selectedProductSubModel,
      fileListSub,
      formDataImgSub,
      isEdit,
      leadteamcheck,
      sellercheck,
      optionsProductCategory,
      selectedProductCategory,
      sellRoleId,
      leadRoleId,
      optionsProductExchange,
      selectedProductExchange,
    };
  },
  watch: {
    selectedBrand: {
      handler(newValue, oldValue) {
        this.fetchProductModels(this.selectedBrand.label);
        // this.selectedProductModel = {}
      },
    },
    selectedProductModel: {
      handler(newValue, oldValue) {
        this.fetchProductYears(
          this.selectedBrand.label,
          this.selectedProductModel.label
        );
        // this.selectedProductYear = {}
      },
    },
    selectedProductYear: {
      handler(newValue, oldValue) {
        this.fetchProductSubModel(
          this.selectedBrand.label,
          this.selectedProductModel.label,
          this.selectedProductYear.label
        );
        // this.selectedProductSubModel = {}
      },
    },
  },
  methods: {
    showToast(variant, icon, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon,
          text,
          variant,
        },
      });
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove(file) {
      this.formDataImg = null;
    },
    handleRemoveSub(file) {
      this.formDataImgSub = null;
    },

    handleChangeImg(file, fileList) {
      let formData = new FormData(); // Create FormData for upload file

      formData.append(file.uid, file.raw, file.name); // Add file for upload
      this.formDataImg = formData;
    },
    handleChangeImgSub(file, fileList) {
      let formData = new FormData(); // Create FormData for upload file

      formData.append(file.uid, file.raw, file.name); // Add file for upload
      this.formDataImgSub = formData;
    },
    async validationForm() {
      //

      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          let saveModel = {};
          if (this.formDataImgSub) {
            this.isWork = true;
            store
              .dispatch("product-list/uploadFileMultiple", this.formDataImgSub)
              .then((res) => {
                if (res.status == 200) {
                  this.productModel.subModelImageId = res.data.data[0];
                }
              });
          }

          if (this.formDataImg) {
            this.isWork = true;
            store
              .dispatch("product-list/uploadFileMultiple", this.formDataImg)
              .then((res) => {
                if (res.status == 200) {
                  this.productModel.product_Image_Id = res.data.data[0];

                  if (router.currentRoute.name === "admin-products-edit") {
                    saveModel = {
                      productId: this.productModel.productId,
                      productCode: this.productModel.productCode,
                      productName: this.productModel.productName,
                      price: this.productModel.price,
                      alert_day: this.productModel.alert_day,
                      minPrice: this.productModel.minPrice,
                      maxPrice: this.productModel.maxPrice,
                      description: this.productModel.description,
                      product_Image_Id: this.productModel.product_Image_Id,
                      productBrand:
                        this.selectedBrand && this.selectedBrand.label,
                      productModels:
                        this.selectedProductModel &&
                        this.selectedProductModel.label,
                      productSubModels:
                        this.selectedProductSubModel &&
                        this.selectedProductSubModel.label,
                      productYear:
                        this.selectedProductYear &&
                        this.selectedProductYear.label,
                      brand: this.productModel.brand,
                      model: this.productModel.model,
                      year: this.productModel.year,
                      subModel: this.productModel.subModel,
                      subModelImageId: this.productModel.subModelImageId,
                      productColor: this.productModel.productColor,
                      teamRoleId: this.leadteamcheck
                        ? this.leadteamcheck
                        : null,
                      salesRoleId: this.sellercheck ? this.sellercheck : null,
                      active: this.productModel.active,
                      product_Category_Id: this.selectedProductCategory.value,
                      exchangeProductGroup: "1", // this.selectedProductExchange,
                    };
                  } else {
                    saveModel = {
                      // productId: this.productModel.productId,
                      productCode: this.productModel.productCode,
                      productName: this.productModel.productName,
                      price: this.productModel.price,
                      alert_day: this.productModel.alert_day,
                      minPrice: this.productModel.minPrice,
                      maxPrice: this.productModel.maxPrice,
                      description: this.productModel.description,
                      product_Image_Id: this.productModel.product_Image_Id,
                      productBrand:
                        this.selectedBrand && this.selectedBrand.label,
                      productModels:
                        this.selectedProductModel &&
                        this.selectedProductModel.label,
                      productSubModels:
                        this.selectedProductSubModel &&
                        this.selectedProductSubModel.label,
                      productYear:
                        this.selectedProductYear &&
                        this.selectedProductYear.label,
                      brand: this.productModel.brand,
                      model: this.productModel.model,
                      year: this.productModel.year,
                      subModel: this.productModel.subModel,
                      subModelImageId: this.productModel.subModelImageId,
                      productColor: this.productModel.productColor,
                      teamRoleId: this.leadteamcheck
                        ? this.leadteamcheck
                        : null,
                      salesRoleId: this.sellercheck ? this.sellercheck : null,
                      active: this.productModel.active,
                      product_Category_Id: this.selectedProductCategory.value,
                      exchangeProductGroup: "1", //this.selectedProductExchange,
                    };
                  }

                  console.log("saveModel", saveModel);
                  console.log("saveModel", JSON.stringify(saveModel));

                  store
                    .dispatch("product-list/addProduct", saveModel)
                    .then((response) => {
                      this.isWork = false;
                      if (response.status === 200) {
                        this.$router
                          .push({ name: "admin-products-list" })
                          .then(() => {
                            this.showToast(
                              "success",
                              "CheckIcon",
                              "Your Work has been saved"
                            );
                          });
                      }
                    })
                    .catch((error) => {
                      this.showToast("danger", "XCircleIcon", error);
                      this.isWork = false;
                    });
                }
              })
              .catch((error) => {
                this.showToast("danger", "XCircleIcon", error);
                this.isWork = false;
              });
          } else if (router.currentRoute.name === "admin-products-edit") {
            this.isWork = true;
            saveModel = {
              productId: this.productModel.productId,
              productCode: this.productModel.productCode,
              productName: this.productModel.productName,
              price: this.productModel.price,
              alert_day: this.productModel.alert_day,
              minPrice: this.productModel.minPrice,
              maxPrice: this.productModel.maxPrice,
              description: this.productModel.description,
              product_Image_Id: this.productModel.product_Image_Id,
              productBrand: this.selectedBrand.label,
              productModels: this.selectedProductModel.label,
              productSubModels: this.selectedProductSubModel.label,
              productYear: this.selectedProductYear.label,
              brand: this.productModel.brand,
              model: this.productModel.model,
              year: this.productModel.year,
              subModel: this.productModel.subModel,
              subModelImageId: this.productModel.subModelImageId,
              productColor: this.productModel.productColor,
              teamRoleId: this.leadteamcheck ? this.leadteamcheck : null,
              salesRoleId: this.sellercheck ? this.sellercheck : null,
              active: this.productModel.active,
              product_Category_Id: this.selectedProductCategory.value,
              exchangeProductGroup: this.selectedProductExchange,
            };
            store
              .dispatch("product-list/addProduct", saveModel)
              .then((response) => {
                this.isWork = false;
                if (response.status === 200) {
                  this.$router
                    .push({ name: "admin-products-list" })
                    .then(() => {
                      this.showToast(
                        "success",
                        "CheckIcon",
                        "Your Work has been saved"
                      );
                    });
                }
              })
              .catch((error) => {
                this.showToast("danger", "XCircleIcon", error);
                this.isWork = false;
              });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.disabless {
  .el-upload {
    display: none !important;
  }
}
</style>
